html {
  height: 100%;
}

html body {
  background-image: url("../img/microbes_bg.png");
  background-repeat: repeat-y;
  background-size: cover;
  background-color: #F5F5F5;
  height: 100%;
  display: flex;
  flex-direction: column;
}

h2 {
  color: $primary;
  font-weight: 600;
}

h3 {
  color: $secondary;
}

h4 {
  text-transform: uppercase;
  font-weight: 600;
}

.header {
  max-height: 180px;

  &__container {
    background-color: rgba($color: $white, $alpha: 0.95);
    @include media-breakpoint-down(sm) {
      display: flex;
      padding: 0;
    }
  }

  &__logo {
    flex: 1;

    &>img {
      max-height: 180px;
    }
  }
}

body {
  padding-left: $navigation-width;
  padding-right: 0 !important;

  @include media-breakpoint-down(sm) {
    padding-left: 0;
  }

}

.content {
  overflow: hidden;
  background-color: rgba($color: $white, $alpha: 0.95);

  @include media-breakpoint-down(sm) {
    overflow: visible;
  }

  &__row {
    overflow: hidden;
    height: 100%;
  }

  &__text {
    &--important {
      font-size: x-large;
      font-weight: bold;
    }
  }

  article {
    overflow: auto;
    height: 100%;

    @include media-breakpoint-down(sm) {
      overflow: visible;
      height: auto;
    }
  }

  &__small {
    font-size: small;
  }

  &__title--small {
    font-size: medium;
  }

  &__registration-prices {
    border: 1px solid $primary;
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: xx-large;

    @include media-breakpoint-down(sm) {
      font-size: medium;
    }
  }

  &__venue-address {
    font-size: small;
  }
}

.sponsors-bar {
  border-left: 1px solid $primary;
  height: 100%;
  overflow: auto;

  @include media-breakpoint-down(sm) {
    margin-top: 2rem;
  }

  &__title {
    display: flex;
    align-items: center;
    text-align: center;

    &::before {
      content: "";
      border: 1px solid $secondary;
      flex: 1;
      height: 1px;
      margin-right: 1rem;
    }

    &::after {
      content: "";
      border: 1px solid $secondary;
      flex: 1;
      height: 1px;
      margin-left: 1rem;
    }

    &--small {
      font-size: medium;
      margin-top: 2rem;
      color: $text-muted;

      &::after {
        border: 1px solid $text-muted;
      }

      &::before {
        border: 1px solid $text-muted;
      }
    }
  }

  &__logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 0.5rem;
  }

  &__logo {
    max-width: 150px;
    object-fit: contain;
    margin: 0.25rem 0;

    &--padded {
      padding: 0.25rem;
    }

    &--non-transparent {
      background-color: $gray-500;
    }

    &--small {
      max-width: 100px;
    }
  }
}

.speakers-list {
  display: flex;
  justify-content: space-evenly;
  margin: 1rem 0;
  flex-wrap: wrap;

  &__image{
    border-radius: 50%;
    max-height: 120px;
    margin-bottom: 0.5rem;
    opacity: 1;
    z-index: 10;

    @include media-breakpoint-down(sm) {
      min-width: 120px;
    }

    &--big{
      max-height: 150px;
      border: 2px solid $secondary;
    }

    &--placeholder{
      height: 120px;
      width: 120px;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 210px;
    margin: 0.5rem 0;

    @include media-breakpoint-down(sm) {
      min-width: 170px;
      margin-bottom: 1rem;
    }
  }

  &__name {
    color: $secondary;
    font-size: large;

    &--big{
      font-size: x-large;
      font-weight: bold;
    }
  }

  &__location{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 220px;
    text-align: center;
    font-size: small;

    @include media-breakpoint-down(sm) {
      max-width: 170px;
    }

    &--big{
      font-size: medium;
    }
  }
}

.custom-offcanvas {
  &__button {
    background-color: $primary;
    width: 3rem;
    border: 0;
    display: none;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(sm) {
      display: flex;
    }
  }

  &__button-icon {
    filter: $btn-close-white-filter;
    background-image: escape-svg(url("../img/list.svg"));
    background-size: cover;
    width: 1.5rem;
    height: 1.5rem;
  }

  &__header {
    position: absolute;
    right: 0;
    z-index: 1;
  }

  &__body {
    overflow: hidden;
    padding: 0;
  }
}

.custom-carousel {
  margin: 0 auto;
  width: 60%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.navigation {
  position: fixed;
  left: 0;
  width: $navigation-width;
  height: 100%;
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    position: relative;
    width: 100%;
    padding: 0;
  }

  &__title {
    font-weight: $font-weight-bold;
    margin: 1rem 0 1rem 0;
    font-family: 'avenir_next_condensed', 'Roboto', sans-serif;
    font-size: 50px;
    text-align: center;
  }

  & .nav-link {

    &:hover,
    &.active {
      background-color: $white;
      color: $primary;
    }
  }
}

.program {
  &__session-title{
    margin-right: 0.5rem;

    @include media-breakpoint-down(sm) {
      margin-right: 0;
      display: inline-block;
    }
  }
}

#mc_embed_signup {
  margin-top: auto;

  & .mc-title {
    color: $white;
    font-size: small;
  }

  & .brandingLogo {
    img {
      height: 20px;
    }
  }
}

.mc-field-group {
  label {
    font-size: x-small;
    color: $white;
  }
}

.mc-title {
  color: $white;
}