@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');

@font-face {
  font-family: 'avenir_next_condensed';
  src: url('../fonts/avenir-next-condensed-bold-webfont.woff2') format('woff2'),
  url('../fonts/avenir-next-condensed-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'avenir_next_condensed';
  src: url('../fonts/avenir-next-condensed-regular-webfont.woff2') format('woff2'),
  url('../fonts/avenir-next-condensed-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
